import type { LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import { login } from "../../shopify.server";
import styles from "./styles.module.css";

import simpleLogo from "../../images/logo-simple-small.png";
import shopifyLogo from "../../images/shopify-elk-ai-logo.png";

import screenshot1 from "../../images/screenshots-1-small.png";
import screenshot2 from "../../images/screenshots-2-small.png";
import screenshot3 from "../../images/screenshots-3-small.png";

import elkai from "../../images/elk-ai-small.png";
import elkspeed from "../../images/elk-speed-small.png";
import elkstructure from "../../images/elk-structure-small.png";

import WebpageFooter from "../../components/WebpageFooter";
import GTag from "../../components/GTag";

export const links = () => [
  { rel: "stylesheet", href: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" },
  { rel: "icon", href: "/public/favicon.ico" },
];

export const meta: MetaFunction = () => {
  return [
    { title: "Elk AI Description Generator | Unique Product Descriptions Effortlessly" },
    {
      property: "og:title",
      content: "Elk AI Description Generator",
    },
    {
      property: "og:image",
      content: simpleLogo,
    },
    {
      name: "description",
      content: "Generate unique and engaging product descriptions for your Shopify store effortlessly with Elk AI Description Generator. Harness the power of AI technology for structured outputs, real-time streaming, and full control over your content.",
    },
    {
      name: "keywords",
      content: "product descriptions, shopify, AI description generator, generate product descriptions, real-time streaming, structured outputs, ecommerce tool, Elk Description Generator",
    }
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  return json({ showForm: Boolean(login) });
};

export default function App() {

  const changeShadowIn = (e) => {
    e.target.classList.remove('shadow-sm');
    e.target.classList.add('shadow-lg');
  }

  const changeShadowOut = (e) => {
    e.target.classList.remove('shadow-lg');
    e.target.classList.add('shadow-sm');
  }

  return (
    <>
      <GTag />
      <main role="main">
        <div id="carouselExampleIndicators" className={"carousel slide " + styles.fullHeight} data-ride="carousel" style={{
          backgroundColor: '#243135',
        }}>
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner h-100">
            <div className="carousel-item active h-100">
              <div className="row align-items-center h-100">
                <div className={"col-md-5 " + styles.carouselFirstImgSec + " " + styles.carouselFirstImgSecAll}>
                  <img src={screenshot1} alt="Elk AI Description Generator: Generate unique product descriptions effortlessly!" className={"img-fluid " + styles.carouselImg} />
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className={"text-left " + styles.carouselText}>
                    <h1>Elk AI Description Generator</h1>
                    <h3>Generate unique product descriptions <span style={{ color: "#22E0B3" }}>effortlessly</span>!</h3>
                    <p>Using our advanced AI technology, get engaging descriptions in seconds for your Shopify store.</p>
                    <img src={shopifyLogo} className="img-fluid w-75" alt="Elk AI and Shopify" />
                    <p><a className="btn btn-lg btn-secondary" href="https://apps.shopify.com/elk-ai-description-generator" role="button">Get Started on Shopify</a></p>
                  </div>
                </div>
                <div className={"col-md-5 " + styles.carouselSecondImgSec}>
                  <img src={screenshot1} alt="Elk AI Description Generator: Generate unique product descriptions effortlessly!" className={"img-fluid " + styles.carouselImg} />
                </div>
              </div>
            </div>
            <div className="carousel-item h-100">
              <div className="row align-items-center h-100">
                <div className={"col-md-5 offset-md-1 " + styles.carouselFirstImgSecAll}>
                  <img src={screenshot2} alt="Elk AI Description Generator App Screenshot: Structured Outputs" className={"img-fluid " + styles.carouselImg} />
                </div>
                <div className="col-md-5">
                  <div className={"text-left " + styles.carouselText}>
                    <h1>Structured Outputs</h1>
                    <h3>Enhance readability with structured outputs.</h3>
                    <p>Receive outputs that are easy to read and well-organized with our <span style={{ color: "#22E0B3" }}>advanced</span> AI technology.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item h-100">
              <div className="row align-items-center h-100">
                <div className={"col-md-5 " + styles.carouselFirstImgSec + " " + styles.carouselFirstImgSecAll}>
                  <img src={screenshot3} alt="Elk AI Description Generator App Screenshot: Full Control with Instructions" className={"img-fluid " + styles.carouselImg} />
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className={"text-left " + styles.carouselText}>
                    <h1><span style={{ color: "#22E0B3" }}>Full Control</span> Over Descriptions</h1>
                    <h3>Tailor your content to your needs.</h3>
                    <p>Input any instructions for tone, specific details, or keywords you want to include.</p>
                  </div>
                </div>

                <div className={"col-md-5 " + styles.carouselSecondImgSec}>
                  <img src={screenshot3} alt="Elk AI Description Generator App Screenshot: Full Control with Instructions" className={"img-fluid " + styles.carouselImg} />
                </div>
              </div>

            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>

        <div className={"marketing " + styles.fullHeight} style={{ backgroundColor: '#eee4cb' }}>
          <div className="container h-100">
            <div className="row align-items-center h-100">
              <div className="col-md-4">
                <div className={"card border-dark bg-transparent shadow-sm " + styles.card} onMouseEnter={changeShadowIn} onMouseLeave={changeShadowOut}>
                  <img className="card-img-top w-50 mx-auto" src={elkai} alt="Elk AI is powered by advanced AI" />
                  <div className="card-body">
                    <h5 className="card-title">AI-Driven Descriptions</h5>
                    <p className="card-text">Generate unique and engaging product descriptions in seconds for your Shopify store using our advanced AI technology.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={"card border-dark bg-transparent shadow-sm " + styles.card} onMouseEnter={changeShadowIn} onMouseLeave={changeShadowOut}>
                  <img className="card-img-top w-50 mx-auto" src={elkspeed} alt="Elk AI streaming features makes generation fast" />
                  <div className="card-body">
                    <h5 className="card-title">Real Time Streaming</h5>
                    <p className="card-text">Get the generated output in a streaming way so you can see the output as fast as it arrives.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={"card border-dark bg-transparent shadow-sm " + styles.card} onMouseEnter={changeShadowIn} onMouseLeave={changeShadowOut}>
                  <img className="card-img-top w-50 mx-auto" src={elkstructure} alt="Elk AI generates structured outputs" />
                  <div className="card-body">
                    <h5 className="card-title">Structured Output</h5>
                    <p className="card-text">Receive structured outputs that help improve the readability of product descriptions.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <WebpageFooter />

        <script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js"></script>
      </main >
    </>
  );
}
